$stars: 250; // Number of start per layer
$depth: 300; // Depth between star layers
$speed: 10s; // Number of seconds to transition between layers
$width: 3000; // Width of the starfield
$height: 1500; // Height of the starfield

.stars {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 2px;
  $box-shadow: ();
  @for $i from 0 through $stars {
    $box-shadow: $box-shadow,
      (random($width)-$width/2 + px)
        (random($height)-$height/2 + px)
        hsl(90, 0, 75 + random(25));
  }
  box-shadow: $box-shadow;
  animation: fly $speed linear infinite;
  transform-style: preserve-3d;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    box-shadow: inherit;
  }
  &:before {
    transform: translateZ(-$depth + px);
    animation: fade1 $speed linear infinite;
  }
  &:after {
    transform: translateZ(-$depth * 2 + px);
    animation: fade2 $speed linear infinite;
  }
}

@keyframes fly {
  from {
    transform: translateZ(0px);
  }
  to {
    transform: translateZ($depth + px);
  }
}

@keyframes fade1 {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111;
  color: #ffffff;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  perspective: 340px;
  height: 100%;
  padding: 5%;
}

.menu {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  h1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #fff;
    text-align: center;
    border-bottom: 5px solid #fff;
    margin: 0 0 30px;
    padding: 15px 0;
    a {
      color: #111;
    }
  }
  p {
    font-family: sans-serif;
    font-size: 12px;
    letter-spacing: 3px;
    text-align: center;
    a {
      text-decoration: underline;
      transition: all 0.2s;
      &:hover {
        color: #ffe040;
      }
    }
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 50px;
  font-weight: 900;
  margin: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

header {
  padding: 100px 0 50px;
  text-align: center;
  a {
    transition: all 0.2s;
    color: #fff;
    border-bottom: 5px solid #333;
    &:hover {
      color: #fff;
      border-color: #555;
    }
  }
}

main {
  padding: 60px 0;
}

#track {
  position: relative;
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  .start {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 80px;
    height: 100%;
    background: #666;
    width: 2px;
  }
  .finish {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 80px;
    height: 100%;
    background: #ffffff;
    width: 20px;
    background-image: linear-gradient(45deg, #333 25%, transparent 25%),
      linear-gradient(-45deg, #333 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #333 75%),
      linear-gradient(-45deg, transparent 75%, #333 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
  .lane {
    position: relative;
    padding: 10px 0;
    border-top: 1px solid #555;
    &:nth-child(odd) {
      background: #191919;
    }
    &:first-child {
      border-top: none;
    }
  }
  .rank {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    background: #555;
    font-size: 30px;
    font-weight: 900;
    color: #ffffff;
    &.rank-1 {
      background: #d6af36;
    }
    &.rank-2 {
      background: #a7a7ad;
    }
    &.rank-3 {
      background: #a77044;
    }
  }
}

.racers {
  position: relative;
  .racer {
    z-index: 1;
    position: relative;
    width: 60px;
    color: #ffffff;
    margin-bottom: 10px;
    transition: all 1s;
    border-radius: 30px;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    .name {
      display: block;
      font-family: "Arial", sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .options {
      display: none;
      position: absolute;
      top: 5px;
      button {
        padding: 4px;
        min-width: auto;
        font-size: 12px;
        font-family: "Arial", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    &:hover {
      .options {
        display: block;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.flames {
  z-index: -1;
  width: 60px;
  height: 54px;
  position: absolute;
  top: -15px;
  left: -20px;
  transform-origin: center bottom;
  animation-name: flicker;
  animation-duration: 1ms;
  animation-delay: 200ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.flame {
  bottom: 0;
  position: absolute;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform: rotate(-135deg) scale(1, 1);
}

.yellow {
  left: 15px;
  bottom: 5px;
  width: 5px;
  height: 5px;
  background: gold;
  box-shadow: 0px 0px 9px 4px gold;
}

.orange {
  left: 10px;
  bottom: 5px;
  width: 15px;
  height: 15px;
  background: orange;
  box-shadow: 0px 0px 9px 4px orange;
}

.red {
  left: 5px;
  width: 25px;
  height: 25px;
  background: OrangeRed;
  box-shadow: 0px 0px 5px 4px OrangeRed;
}

.white {
  left: 15px;
  bottom: 4px;
  width: 15px;
  height: 15px;
  background: white;
  box-shadow: 0px 0px 9px 4px white;
}

@keyframes flicker {
  0% {
    transform: rotate(-1deg);
  }
  20% {
    transform: rotate(1deg);
  }
  40% {
    transform: rotate(-1deg);
  }
  60% {
    transform: rotate(1deg) scaleY(1.04);
  }
  80% {
    transform: rotate(-2deg) scaleY(0.92);
  }
  100% {
    transform: rotate(1deg);
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 60px;
  .col {
    input {
      display: block;
      width: 100%;
      font-size: 20px;
      margin-bottom: 15px;
      padding: 15px;
      border: 2px solid #ddd;
    }
    button {
      width: 100%;
    }
  }
}
p {
  &.error {
    padding: 30px;
    text-align: center;
    margin: 0 0 30px;
    background: #e65122;
    color: #ffffff;
    font-size: 30px;
    text-transform: uppercase;
  }
  &.success {
    padding: 30px;
    text-align: center;
    margin: 0 0 30px;
    background: #41ca1f;
    color: #ffffff;
    font-size: 30px;
    text-transform: uppercase;
  }
}

.button {
  --border-width: 4px;
  --corner-value: 20px;
  --cropped-corner: polygon(
    100% 0,
    100% calc(100% - var(--corner-value)),
    calc(100% - var(--corner-value)) 100%,
    0 100%,
    0 0
  );
  background: transparent;
  color: inherit;
  font-size: 30px;
  padding: 30px;
  border: 4px solid #ffffff;
  font-family: inherit;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-clip-path: var(--cropped-corner);
  clip-path: var(--cropped-corner);
  position: relative;
  width: 100%;
  transition: all 0.18s ease-out;
  letter-spacing: 2px;
  margin-bottom: 30px;

  animation-iteration-count: 1;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 20px;
    height: calc(20px * 2);
    bottom: calc(20px * -1);
    right: calc(20px * -0.3);
    background: #ffffff;
    transform: rotate(45deg) translateX(0%);
  }

  &:hover {
    color: #060606;
    background-color: #ffffff;
    letter-spacing: 1px;
    cursor: pointer;
  }

  &.button-small {
    padding: 30px;
    font-size: 20px;
  }

  &[disabled] {
    opacity: 0.5;
    &:hover {
      color: inherit;
      background-color: transparent;
      letter-spacing: 2px;
      cursor: pointer;
    }
  }
}

.two-buttons {
  button {
    width: 48%;
    margin-right: 2%;
    &:last-child {
      margin-left: 2%;
      margin-right: 0;
    }
  }
}

.field {
  border: 4px solid #ffffff;
  margin-bottom: 30px;
  padding: 10px;
}

label {
  display: block;
}

input {
  width: 100%;
  outline: none;
  text-align: center;
  background: transparent;
  color: inherit;
  font-size: 30px;
  border: none;
  border-bottom: 4px solid #ffffff;
  padding: 15px 0;
}
